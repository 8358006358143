import React from "react"
import Meaning from "../meaning/Meaning"
import Phonetic from "../phonetic/Phonetic"
import "./results.css"
export default function Results(props){
    if (props.results){
    return (
      <div className="results">
        <section>
          <h2>{props.results.word}</h2>

          {props.results.phonetics.map(function (phonetic, index) {
            return (
              <div key={index}>
                <Phonetic phonetic={phonetic} />
              </div>
            );
          })}
        </section>
        
        {props.results.meanings.map(function (meaning, index) {
          return (
            <section key={index}>
              <Meaning meaning={meaning} />
            </section>
          );
        })}
      </div>
    );
    }else{
        return null
    }
    
    
}